<template>
  <div>
    <my-nav-bar
      :title="employeeName"
      left-text="返回"
      left-arrow
      :right-text="isHistory ? '' : rightText"
      @click-right="isHistory ? () => {} : handleCancel()"
    />
    <van-sticky v-if="type === 2" offset-top="1.22667rem">
      <van-cell title="备注" is-link @click="showInRemarkDialog = true" />
    </van-sticky>
    <div class="app-main-container">
      <div :class="{'list': isHistory, 'list-finish': !isHistory}">
        <div v-for="(goods, index) in list" :key="index" class="goods-container">
          <van-image v-if="goods.in_at" class="goods-finished" :src="require('@/assets/images/yiyanshou.png')" />
          <promote-sales-detail-goods
            :goods="goods"
            :type="type"
            :is-history="isHistory"
            @shortage="handleShortage"
            @in-confirm="handleShowInDialog"
          />
        </div>
      </div>
    </div>
    <div v-if="!isHistory" class="fixed-bottom-btn">
      <van-button :disabled="canFinish" block square @click="handleFinished">{{ type === 1 ? '已出库' : '已核对' }}</van-button>
    </div>

    <my-dialog v-model="showShortageDialog" title="缺货" @confirm="handleShortageConfirm">
      <div class="shortage-dialog">
        <van-field
          ref="shortageInput"
          v-model="shortageDialogData.quantity"
          :label="`现货数量(${shortageDialogData.least_unit})`"
          type="number"
          placeholder="请输入现货数量"
          clearable
        />
      </div>
    </my-dialog>

    <my-dialog
      v-model="showInDialog"
      :title="isHistory ? '查看凭证' : '上传凭证'"
      :show-confirm-button="!isHistory"
      @confirm="handleInConfirm"
    >
      <div class="upload-container">
        <van-uploader
          v-model="fileList"
          :disabled="Boolean(isHistory)"
          :max-count="6"
          capture="camera"
          :before-read="beforeRead"
          :after-read="afterRead"
          @delete="handleDelete"
        />
      </div>
    </my-dialog>

    <my-dialog
      v-model="showInRemarkDialog"
      :title="isHistory ? '查看备注' : '填写备注'"
      :show-confirm-button="!isHistory"
      @confirm="showInRemarkDialog = false"
    >
      <van-field v-model="inRemark" :disabled="Boolean(isHistory)" type="textarea" placeholder="备注" autosize show-word-limit maxlength="255" />
    </my-dialog>

  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getPromoteSalesDetails, finishPromoteSalesPlan, promoteSalesCancel, promoteSalesUploadImage, promoteSalesInConfirm } from '@/api/promote-sales'
import PromoteSalesDetailGoods from '@/components/PromoteSalesGoods/detail'
import MyDialog from '@/components/my-dialog'
import Compressor from 'compressorjs'
import { now, sortByKey } from '@/utils'
export default {
  components: { myNavBar, PromoteSalesDetailGoods, MyDialog },
  data() {
    return {
      isHistory: Number(this.$route.query.is_history),
      employeeName: this.$route.query.employee_name,
      type: Number(this.$route.query.type),
      planId: this.$route.query.plan_id,
      list: [],
      showShortageDialog: false,
      shortageDialogData: {},
      shortageData: [],
      showInDialog: false,
      showInRemarkDialog: false,
      inRemark: '',
      fileList: [],
      handleDetail: {}
    }
  },
  computed: {
    canFinish() {
      if (this.type === 2) {
        const noConfirms = this.list.filter(d => {
          return d.in_at === null
        })
        return this.list.length === 0 || noConfirms.length > 0
      }

      return this.list.length === 0
    }
  },
  created() {
    this.rightText = this.type === 1 ? '取消出库' : '取消核对'
    const params = {
      plan_id: this.planId
    }
    this.beginLoad()
    getPromoteSalesDetails(params).then(res => {
      this.endLoad()
      res.data.forEach(detail => {
        detail.shortage_quantity = 0
      })
      this.list = res.data
    })
  },
  methods: {
    handleFinished() {
      let msg = ''
      const isOut = this.type === 1
      if (isOut) {
        msg = '操作完成将无法修改，确定商品已出库了吗？'
      } else {
        msg = '操作完成将无法修改，确定商品已核对了吗？'
      }
      this.confirm(msg, '提示').then(() => {
        this.beginLoad()
        const data = {
          type: this.type,
          plan_id: this.planId,
          shortage_data: this.shortageData,
          in_remark: this.inRemark
        }
        finishPromoteSalesPlan(data).then(res => {
          this.success(res.msg)
          this.$router.back()
          this.$eventBus.$emit('refresh')
        })
      })
    },
    handleCancel() {
      if (this.list.length === 0) {
        this.fail('无法完成操作')
        return
      }
      this.beginLoad()
      const data = {
        plan_id: this.planId
      }
      promoteSalesCancel(data).then(res => {
        this.success(res.msg)
        this.$router.back()
      })
    },
    handleShortage(goods) {
      this.showShortageDialog = true
      this.shortageDialogData = {
        id: goods.id,
        quantity: goods.total_quantity - goods.out_quantity,
        least_unit: goods.least_unit
      }
      this.$nextTick(() => {
        const shortageInput = this.$refs.shortageInput
        shortageInput.focus()
        shortageInput.$refs.input.select()
      })
    },
    handleShortageConfirm() {
      const hasDetail = this.list.find(detail => detail.id === this.shortageDialogData.id)
      const orderQuantity = hasDetail.total_quantity - hasDetail.out_quantity
      if (this.shortageDialogData.quantity < 0) {
        this.fail('现货数量不能小于0')
        return
      }
      if (this.shortageDialogData.quantity > orderQuantity) {
        this.fail('现货数量不能大于安排数量')
        return
      }

      if (orderQuantity === Number(this.shortageDialogData.quantity)) {
        const hasDataIndex = this.shortageData.findIndex(detail => detail.id === this.shortageDialogData.id)
        if (hasDataIndex !== -1) {
          this.shortageData.splice(hasDataIndex, 1)
        }
        hasDetail.shortage_quantity = 0
      } else {
        const shortageQuantity = orderQuantity - Number(this.shortageDialogData.quantity)
        const hasData = this.shortageData.find(detail => detail.id === this.shortageDialogData.id)
        if (hasData) {
          hasData.quantity = shortageQuantity
        } else {
          this.shortageData.push({
            id: hasDetail.id,
            quantity: shortageQuantity
          })
        }

        hasDetail.shortage_quantity = shortageQuantity
      }
    },
    handleShowInDialog(d) {
      this.showInDialog = true
      this.handleDetail = d
      this.fileList = d.in_images.map(url => {
        return { url }
      })
    },
    beforeRead(file) {
      return new Promise((resolve) => {
        // compressorjs 默认开启 checkOrientation 选项
        // 会将图片修正为正确方向
        new Compressor(file, {
          convertTypes: ['image/png', 'image/webp'],
          convertSize: 100000,
          quality: 0.2,
          success: (result) => {
            const newFile = new File([result], file.name, { type: file.type })
            resolve(newFile)
          },
          error(err) {
            console.log(err.message)
          }
        })
      })
    },
    afterRead(fileObj) {
      fileObj.status = 'uploading'
      fileObj.message = '上传中…'
      promoteSalesUploadImage(fileObj.file).then(res => {
        fileObj.status = ''
        fileObj.message = ''
        this.handleDetail.in_images.push(res.data.image)
        fileObj.url = res.data.image
      }).catch(() => {
        fileObj.status = 'failed'
        fileObj.message = '上传失败'
      })
    },
    handleDelete(fileObj) {
      const index = this.handleDetail.in_images.findIndex(imageUrl => fileObj.url === imageUrl)
      this.handleDetail.in_images.splice(index, 1)
    },
    handleInConfirm() {
      this.beginLoad()
      const data = {
        id: this.handleDetail.id,
        in_images: this.handleDetail.in_images
      }
      promoteSalesInConfirm(data).then(() => {
        this.endLoad()
        this.handleDetail.in_at = now()
        // 将已核对的移到最后面
        this.list.sort(sortByKey('in_at', 'asc'))
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  padding: 10px;
}
.list-finish {
  padding: 10px;
  margin-bottom: 50px;
}
.goods-container {
  margin-bottom: 10px;
  position: relative;
}
.goods-finished {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: auto;
  z-index: 10;
}
.goods-container:last-child {
  margin-bottom: 0;
}
.shortage-dialog {
  padding: 10px 0;
}
.upload-container {
  padding: 10px 25px;
}
</style>
