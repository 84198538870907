import request from '@/utils/request'

export function getPromoteSalesPlans(params) {
  return request({
    method: 'get',
    url: 'promote-sales/plans',
    params
  })
}

export function getPromoteSalesDetails(params) {
  return request({
    method: 'get',
    url: 'promote-sales/details',
    params
  })
}

export function finishPromoteSalesPlan(data) {
  return request({
    method: 'post',
    url: 'promote-sales/finished',
    data
  })
}

export function getPromoteSalesPlanCount() {
  return request({
    method: 'get',
    url: 'promote-sales/my-count'
  })
}

export function getPromoteSalesPlanDetails() {
  return request({
    method: 'get',
    url: 'promote-sales/my-details'
  })
}

export function promoteSalesOrder(data) {
  return request({
    method: 'post',
    url: 'promote-sales/order',
    data
  })
}

export function promoteSalesCancel(data) {
  return request({
    url: `promote-sales/cancel`,
    method: 'post',
    data
  })
}

export function promoteSalesInConfirm(data) {
  return request({
    url: `promote-sales/in-confirm`,
    method: 'post',
    data
  })
}

export function promoteSalesUploadImage(file) {
  const form = new FormData()
  form.append('image', file)
  return request({
    method: 'post',
    url: 'promote-sales/upload',
    data: form,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export function getEmployees(params) {
  return request({
    url: `promote-sales/employee`,
    method: 'get',
    params
  })
}

export function transout(data) {
  return request({
    url: `promote-sales/transout`,
    method: 'post',
    data
  })
}
