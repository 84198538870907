var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": _vm.employeeName,
      "left-text": "返回",
      "left-arrow": "",
      "right-text": _vm.isHistory ? '' : _vm.rightText
    },
    on: {
      "click-right": function clickRight($event) {
        _vm.isHistory ? function () {} : _vm.handleCancel();
      }
    }
  }), _vm.type === 2 ? _c('van-sticky', {
    attrs: {
      "offset-top": "1.22667rem"
    }
  }, [_c('van-cell', {
    attrs: {
      "title": "备注",
      "is-link": ""
    },
    on: {
      "click": function click($event) {
        _vm.showInRemarkDialog = true;
      }
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "app-main-container"
  }, [_c('div', {
    class: {
      'list': _vm.isHistory,
      'list-finish': !_vm.isHistory
    }
  }, _vm._l(_vm.list, function (goods, index) {
    return _c('div', {
      key: index,
      staticClass: "goods-container"
    }, [goods.in_at ? _c('van-image', {
      staticClass: "goods-finished",
      attrs: {
        "src": require('@/assets/images/yiyanshou.png')
      }
    }) : _vm._e(), _c('promote-sales-detail-goods', {
      attrs: {
        "goods": goods,
        "type": _vm.type,
        "is-history": _vm.isHistory
      },
      on: {
        "shortage": _vm.handleShortage,
        "in-confirm": _vm.handleShowInDialog
      }
    })], 1);
  }), 0)]), !_vm.isHistory ? _c('div', {
    staticClass: "fixed-bottom-btn"
  }, [_c('van-button', {
    attrs: {
      "disabled": _vm.canFinish,
      "block": "",
      "square": ""
    },
    on: {
      "click": _vm.handleFinished
    }
  }, [_vm._v(_vm._s(_vm.type === 1 ? '已出库' : '已核对'))])], 1) : _vm._e(), _c('my-dialog', {
    attrs: {
      "title": "缺货"
    },
    on: {
      "confirm": _vm.handleShortageConfirm
    },
    model: {
      value: _vm.showShortageDialog,
      callback: function callback($$v) {
        _vm.showShortageDialog = $$v;
      },
      expression: "showShortageDialog"
    }
  }, [_c('div', {
    staticClass: "shortage-dialog"
  }, [_c('van-field', {
    ref: "shortageInput",
    attrs: {
      "label": "\u73B0\u8D27\u6570\u91CF(".concat(_vm.shortageDialogData.least_unit, ")"),
      "type": "number",
      "placeholder": "请输入现货数量",
      "clearable": ""
    },
    model: {
      value: _vm.shortageDialogData.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.shortageDialogData, "quantity", $$v);
      },
      expression: "shortageDialogData.quantity"
    }
  })], 1)]), _c('my-dialog', {
    attrs: {
      "title": _vm.isHistory ? '查看凭证' : '上传凭证',
      "show-confirm-button": !_vm.isHistory
    },
    on: {
      "confirm": _vm.handleInConfirm
    },
    model: {
      value: _vm.showInDialog,
      callback: function callback($$v) {
        _vm.showInDialog = $$v;
      },
      expression: "showInDialog"
    }
  }, [_c('div', {
    staticClass: "upload-container"
  }, [_c('van-uploader', {
    attrs: {
      "disabled": Boolean(_vm.isHistory),
      "max-count": 6,
      "capture": "camera",
      "before-read": _vm.beforeRead,
      "after-read": _vm.afterRead
    },
    on: {
      "delete": _vm.handleDelete
    },
    model: {
      value: _vm.fileList,
      callback: function callback($$v) {
        _vm.fileList = $$v;
      },
      expression: "fileList"
    }
  })], 1)]), _c('my-dialog', {
    attrs: {
      "title": _vm.isHistory ? '查看备注' : '填写备注',
      "show-confirm-button": !_vm.isHistory
    },
    on: {
      "confirm": function confirm($event) {
        _vm.showInRemarkDialog = false;
      }
    },
    model: {
      value: _vm.showInRemarkDialog,
      callback: function callback($$v) {
        _vm.showInRemarkDialog = $$v;
      },
      expression: "showInRemarkDialog"
    }
  }, [_c('van-field', {
    attrs: {
      "disabled": Boolean(_vm.isHistory),
      "type": "textarea",
      "placeholder": "备注",
      "autosize": "",
      "show-word-limit": "",
      "maxlength": "255"
    },
    model: {
      value: _vm.inRemark,
      callback: function callback($$v) {
        _vm.inRemark = $$v;
      },
      expression: "inRemark"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }