<template>
  <div class="goods">
    <div class="image">
      <van-image :src="goods.thumb_image" />
    </div>
    <div class="info">
      <div class="name">{{ goods.name }}</div>
      <div class="size">规格：1 * {{ goods.size }}</div>
      <div class="quantity">数量：{{ type === 1 ? goods.total_quantity - goods.out_quantity - goods.shortage_quantity : goods.quantity }}{{ goods.least_unit }}</div>
      <div v-if="goods.shortage_quantity" class="quantity">缺货：{{ goods.shortage_quantity }}{{ goods.least_unit }}</div>
    </div>
    <div v-if="type === 1" class="btn">
      <van-button size="small" type="danger" @click="$emit('shortage', goods)">缺货</van-button>
    </div>
    <div v-if="type === 2" class="btn">
      <van-button size="small" type="primary" @click="$emit('in-confirm', goods)">{{ isHistory ? '查看凭证' : '确认核对' }}</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromoteSalesGoods',
  props: {
    goods: Object,
    type: Number,
    isHistory: Number
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/color";
.goods {
  display: flex;
  padding: 10px;
  background-color: #fff;
  position: relative;
}
.image, .image .van-image {
  width: 100px;
  height: 100px;
}
.info {
  margin-left: 10px;
  width: 100%;
}
.name {
  font-size: 15px;
  word-break: break-all;
}
.size, .storage, .quantity {
  font-size: 13px;
  color: #989898;
  margin-top: 2px;
}
.btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
