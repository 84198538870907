var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "goods"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('van-image', {
    attrs: {
      "src": _vm.goods.thumb_image
    }
  })], 1), _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.goods.name))]), _c('div', {
    staticClass: "size"
  }, [_vm._v("规格：1 * " + _vm._s(_vm.goods.size))]), _c('div', {
    staticClass: "quantity"
  }, [_vm._v("数量：" + _vm._s(_vm.type === 1 ? _vm.goods.total_quantity - _vm.goods.out_quantity - _vm.goods.shortage_quantity : _vm.goods.quantity) + _vm._s(_vm.goods.least_unit))]), _vm.goods.shortage_quantity ? _c('div', {
    staticClass: "quantity"
  }, [_vm._v("缺货：" + _vm._s(_vm.goods.shortage_quantity) + _vm._s(_vm.goods.least_unit))]) : _vm._e()]), _vm.type === 1 ? _c('div', {
    staticClass: "btn"
  }, [_c('van-button', {
    attrs: {
      "size": "small",
      "type": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('shortage', _vm.goods);
      }
    }
  }, [_vm._v("缺货")])], 1) : _vm._e(), _vm.type === 2 ? _c('div', {
    staticClass: "btn"
  }, [_c('van-button', {
    attrs: {
      "size": "small",
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('in-confirm', _vm.goods);
      }
    }
  }, [_vm._v(_vm._s(_vm.isHistory ? '查看凭证' : '确认核对'))])], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }